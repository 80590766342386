import {useLayoutEffect, useState} from "react";

export const redirectToSection = (id: string) => {
    document!.getElementById(id)!.scrollIntoView({
        block: "start",
        behavior: "smooth",
    })
};

export const sectionResize = (heightTo: number, section?: Element, swiper_element?: Element) => {
    return () => {
        const height = swiper_element?.scrollHeight ?? undefined
        if (section && height)
            section.setAttribute('style', `height:${((heightTo * height) / 937)}px`)
    }
}

export const sectionsResize = (heightTo: number, section?: HTMLCollectionOf<Element>, swiper_element?: Element) => {
    return () => {
        const height = swiper_element?.scrollHeight ?? undefined
        if (section && height)
            for (let i = 0; i < section.length; i++) {
                section[i].setAttribute('style', `height:${((heightTo * height) / 937)}px`)
            }
    }
}

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);
    useLayoutEffect(() => {
        const updateSize = () => setIsMobile(window.innerWidth < 760);
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return isMobile;
}