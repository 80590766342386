import React, {CSSProperties, useRef, useState} from "react"
import "./style.scss"
import ButtonIGL from "../../../../components/button";
import {FormControl, MenuItem, Select} from "@material-ui/core";


function Formulario() {
    const [currentAluno, setCurrentAluno] = useState<number>(1)
    const [data, setData] = useState({
        resp: "",
        email: "",
        tel: "",
        alunos: [{nome_aluno: "", serie: 0}],
    })
    const [error, setError] = useState<any>({})

    const handleError = () => {
        let newError = {}
        Object.entries(data).forEach(
            ([key, value]) => {
                if (key === 'alunos') {
                    for (let i in (value as [])) {
                        Object.entries(i).forEach(([key, value]) => {
                            console.log(i)
                            // (value === "" || value === 0) && (newError = {...newError, [key]: "Preencha este campo"});            
                        })
                    }
                } else {
                    value === "" && (newError = {...newError, [key]: "Preencha este campo"});
                }
            }
        );
        setError(newError)
        return Object.keys(newError).length === 0
    }

    const handleChangeParent = (event: React.ChangeEvent<{ name: string, value: unknown }>) => {
        setData({...data, [event.target.name as string]: event.target.value})
    }

    const handleChangeChild = (event: React.ChangeEvent<{ name?: string, value: unknown }>, number: number) => {
        console.log(event.target.name, event.target.value)
        const newData = {...data}
        newData.alunos[number] = {...newData.alunos[number], [event.target.name as string]: event.target.value}
        setData(newData)
    }

    const form = useRef<HTMLFormElement>(null)


    function getResp1() {
        return <>
            <label style={{gridArea: 'nome-resp'}}>
                <p>Nome responsável:</p>
                <input className={'input-forms'} name={"resp"} type="text" onChange={handleChangeParent}/>
                {error['resp'] && <p className={'error'}>{error['resp']}</p>}
            </label>

            <label style={{gridArea: 'mail'}}>
                <p>Email:</p>
                <input className={'input-forms'} name={"email"} type="text" onChange={handleChangeParent}/>
                {error['email'] && <p className={'error'}>{error['email']}</p>}
            </label>

            <label style={{gridArea: 'tel'}}>
                <p>Telefone de contato:</p>
                <input className={'input-forms'} name={"tel"} type="text" onChange={handleChangeParent}/>
                {error['tel'] && <p className={'error'}>{error['tel']}</p>}
            </label>
        </>;
    }

    function getAluno(number: number) {
        return <>
            <label style={{gridArea: 'nome-aluno'}}>
                <p>Nome do aluno:</p>
                <input className={'input-forms'} name="nome_aluno" type="text" onChange={(e) => handleChangeChild(e, number)} value={data.alunos[number].nome_aluno}/>
                {error['nome_aluno'] && <p className={'error'}>{error['nome_aluno']}</p>}
            </label>

            <label>
                <p>Série que deseja entrar:</p>
                <FormControl variant="outlined">
                    <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={data.alunos[number].serie}
                        name="serie"
                        IconComponent={() => (
                            <i className="fas fa-chevron-down"/>
                        )}
                        onChange={(e) => handleChangeChild(e, number)}
                    >
                        <MenuItem value={0}>
                            <em>Selecione uma serie</em>
                        </MenuItem>
                        <MenuItem value={'1º Período - Castelo'}>1º Período - Castelo</MenuItem>
                        <MenuItem value={'2º Período - Castelo'}>2º Período - Castelo</MenuItem>
                        <MenuItem value={'3º Período - Castelo'}>3º Período - Castelo</MenuItem>
                        <MenuItem value={'4º Período - Castelo'}>4º Período - Castelo</MenuItem>
                        <MenuItem value={'5º Período - Castelo'}>5º Período - Castelo</MenuItem>
                        <MenuItem value={'1º ano - Castelo'}>1º ano - Castelo</MenuItem>
                        <MenuItem value={'2º ano - Castelo'}>2º ano - Castelo</MenuItem>
                        <MenuItem value={'3º ano - Castelo'}>3º ano - Castelo</MenuItem>
                        <MenuItem value={'4º ano - Castelo'}>4º ano - Castelo</MenuItem>
                        <MenuItem value={'5º ano - Castelo'}>5º ano - Castelo</MenuItem>
                        <MenuItem value={'6º ano - Glória'}>6º ano - Glória</MenuItem>
                        <MenuItem value={'7º ano - Glória'}>7º ano - Glória</MenuItem>
                        <MenuItem value={'8º ano - Glória'}>8º ano - Glória</MenuItem>
                        <MenuItem value={'9º ano - Glória'}>9º ano - Glória</MenuItem>
                        <MenuItem value={'1º ano médio - Glória'}>1º ano médio - Glória</MenuItem>
                        <MenuItem value={'2º ano médio - Glória'}>2º ano médio - Glória</MenuItem>
                        <MenuItem value={'3º ano médio - Glória'}>3º ano médio - Glória</MenuItem>
                    </Select>
                </FormControl>
                {error['serie'] && <p className={'error'}>{error['serie']}</p>}
            </label>

        </>;
    }


    const addAluno = () => {
        setCurrentAluno(currentAluno + 1)
        data.alunos.push({nome_aluno: "", serie: 0});
    }

    const removeAluno = () => {
        if (data.alunos.length > 1) {
            data.alunos.splice(currentAluno - 1, 1);
            setCurrentAluno(currentAluno > 1 ? (currentAluno - 1) : 1)
            setData({...data})
        }

    }

    const nextAluno = () => {
        if (currentAluno < data.alunos.length) {
            setCurrentAluno(currentAluno + 1)
        }
    }

    const backAluno = () => {
        if (currentAluno > 1) {
            setCurrentAluno(currentAluno - 1)
        }
    }


    const show: CSSProperties = {visibility: "visible", opacity: 1}
    const disappear: CSSProperties = {visibility: "hidden", opacity: 0}

    const alunoStringReduce = (prev: string, current: { nome_aluno?: string, serie?: any }, index: number) => prev + `%0A%0ANome%20Aluno%20${index + 1}%3A%20${current.nome_aluno}%0ASerie%20de%20entrada%3A%20${current.serie}`;

    return (
        <div className={"forms-container"} id={"forms-section"}>
            <h2>Estude conosco</h2>
            <form ref={form} onSubmit={(event) => {
                event.preventDefault();
                return false;
            }}>
                {getResp1()}

                {
                    getAluno(currentAluno - 1)
                }

                <div className={'aluno-controller'}>
                    <div style={data.alunos.length <= 1 ? disappear : show}>
                        <i className="fas fa-chevron-left" onClick={backAluno}
                           style={1 === currentAluno ? disappear : show}/>
                        <p>{currentAluno}</p>
                        <i className="fas fa-chevron-right" onClick={nextAluno}
                           style={(data.alunos.length) === currentAluno ? disappear : show}/>
                    </div>
                    <ButtonIGL
                        action={addAluno}
                        text={"Adicionar Aluno"}
                    >
                    </ButtonIGL>
                    <i className="far fa-trash-alt" onClick={removeAluno}/>
                </div>

                <ButtonIGL action={() => {
                    if (handleError()) {
                        let text = `Ol%C3%A1!%20Tenho%20interesse%20em%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20matr%C3%ADcula%20no%20Gabriela%20Leopoldina.%20%0ASeguem%20meus%20dados%3A%0A%0AEmail%3A%20${data.email}%0ATel.%3A%20${data.tel}`
                        text = data.alunos.length > 1 ? data.alunos.reduce(alunoStringReduce, text) : (text + `%0A%0ANome%20Aluno%3A%20${data.alunos[0].nome_aluno}%0ASerie%20de%20entrada%3A%20${data.alunos[0].serie}`);
                        window.open(
                            `https://wa.me/+553135682691?text=${text}`,
                            '_blank'
                        );
                    }

                }} text={"Estude Conosco"}>
                    <i className="fab fa-whatsapp"/>
                </ButtonIGL>


            </form>
        </div>
    )
}

export default Formulario

























