import React, {useContext, useEffect} from "react"
import "./style.scss"

import Carousel from "./components/carousel";
import Video from "./components/video";
import Mission from "./components/mission";
import {line_draw, line_draw_mobile} from "../../assets/home";
import {RouteProps} from "react-router";
import {redirectToSection, useIsMobile} from "../../utils";
import Unidades from "./components/unidade";
import Casas from "./components/unidade/casas";
import Formulario from "./components/formulario";
import {HeaderContext} from "../../components/headerbar/provider/header";


function Home(props: RouteProps) {
    const isMobile = useIsMobile();
    const {setShowHeader} = useContext(HeaderContext);


    const matchSections: { [key: string]: string; } = {
        "admissao": "forms-section",
        "igl": "video-section",
        "castelo": "unid-castelo",
        "gloria": "unid-gloria",
        "missoes": "mission-section",
        "casas": "casas-section",
    }


    useEffect(() => {
        const hash = props.location?.hash.split("#")[1] ?? "";
        if (hash !== "" && hash !== null && matchSections[hash] !== null) {
            redirectToSection(matchSections[hash]);
        }
    });

    useEffect(() => {
        setShowHeader(false)
        return () => setShowHeader(true)
    }, [])

    return (
        <main className="home-page">
            {!isMobile && <img id={"line"} className={"line-draw"} src={line_draw} alt=""/>}
            <Carousel/>
            {isMobile && <img id={"line"} className={"line-draw"} src={line_draw_mobile} alt=""/>}
            <Video/>
            <Mission/>
            <Unidades/>
            <Casas/>
            <Formulario/>
        </main>
    )
}

export default Home;