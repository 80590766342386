import './style.scss'
import {Fab, Slide, useScrollTrigger} from '@material-ui/core';
import logo from '../../assets/logo.png'
import {Link} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";


function Header({showAllTime}: any) {
    const trigger = useScrollTrigger({target: window});
    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
            }
            setY(window.scrollY);
        }, [y]
    );


    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);

        };
    }, [handleNavigation]);

    const handleEstude = () => {
        document!.getElementById("forms-section")!.scrollIntoView({
            block: "start",
            behavior: "smooth",
        })
    }

    //trigger || (showAllTime ?? false)
    return (
        <Slide appear={true} direction="down" in={showAllTime ? !trigger : trigger} timeout={{enter: 10}}>
            <div className="header-class not-show-moblie" style={{position: "fixed", width: "calc(100vw - (100vw - 100%))", top: "0%"}}>
                <Link to="/" className="not-show-moblie"><p>Home</p></Link>
                {/*<Link className="not-show-moblie" to="/school"><p>A escola</p></Link>*/}
                <a className="not-show-moblie" href="/build"><p>A escola</p></a>
                {/*<Link to="/familia" className="not-show-moblie"><p>Família IGL</p></Link>*/}
                <a href="/build" className="not-show-moblie"><p>Família IGL</p></a>
                <Link to="/"><img src={logo} alt=""/></Link>
                {/*<Link to="/unidades" className="not-show-moblie"><p>Unidade</p></Link>*/}
                <a href="/build" className="not-show-moblie"><p>Unidade</p></a>
                <Link to="/galeria" className="not-show-moblie"><p>Galeria</p></Link>
                <Fab variant="extended" aria-label="Contatar" color="primary" onClick={handleEstude}>
                    Estude Conosco
                </Fab>
            </div>
        </Slide>
    )
}

export default Header;