import {createContext, useState} from "react";
import Header from "../index";

export const HeaderContext = createContext({} as any)

const HeaderProvider = ({children}: any) => {
    const [showHeader, setShowHeader] = useState(true)

    return (
        <HeaderContext.Provider value={{showHeader, setShowHeader}}>
            <Header showAllTime={showHeader}/>

            {children}
        </HeaderContext.Provider>
    )
}

export default HeaderProvider