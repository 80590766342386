import Unidade from "./unid-abstract";
import "./style.scss"

import {cambridge, img_castelo_1, img_castelo_2, img_castelo_3, img_gloria_1, img_gloria_2, img_gloria_3} from "../../../../assets/home";
import React from "react";


function Unidades() {


    return (
        <div className={"unidades-container"} id={"unidades-container"}>
            <h2>Unidades</h2>

            <Unidade id="unid-castelo" img={[img_castelo_1, img_castelo_2, img_castelo_3]}
                     title={<h3>Castelo <span>NOVO!</span></h3>}
                     details={<p>Em 2022 quebraremos uma nova barreira. <br/> Um espaço exclusivo para <span>Educação infantil</span> e <br/> <span>Ensino fundamental.</span>
                     </p>}
                     redirect={() => {
                         window.location.href = '/build'
                     }}
            />
            <Unidade id="unid-gloria" img={[img_gloria_1, img_gloria_2, img_gloria_3]} title={<h3>Glória</h3>}
                     details={<p>Um compromisso inegociável com a formação de <br/> nossos adolescentes, humana e academicamente. <br/>Enfoque na qualidade
                         do Ensino no <span>Fundamental II</span> e<br/>
                         <span>Ensino médio</span>.</p>}
                     redirect={() => {
                         window.location.href = '/build'
                     }}
            />
        </div>
    )
}

export default Unidades;
