import React from "react"
import Build from "../build";

function School() {
    return (
        <main>
            <Build/>
        </main>
    )


}

export default School