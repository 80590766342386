import React, {ReactNode} from "react"
import "./style.scss"
import ButtonIGL from "../../../../../components/button";
import {Swiper, SwiperSlide} from 'swiper/react';

interface UnidadeProp {
    id: string
    img: string[];
    title: ReactNode;
    details: ReactNode;
    logo?: string
    redirect: Function;
}


function Unidade(props: UnidadeProp) {

    return (
        <div className={"unidade-container"} id={props.id}>

            <div className={'teste'}>
                <Swiper
                    effect={'fade'}
                    centeredSlides={true}
                    pagination={true}
                    autoplay={{
                        "delay": 2500,
                        "disableOnInteraction": false
                    }}

                >
                    {
                        props.img.map(
                            (value) =>
                                <SwiperSlide>
                                    <img src={value} alt=""/>
                                </SwiperSlide>
                        )
                    }

                </Swiper>
            </div>

            <div className={"description"}>
                <div className={"right"}>
                    {props.title}
                    {props.details}
                </div>

                <div className={"left"}>
                    {props.logo && window.innerWidth < 760 && <img src={props.logo} alt=""/>}
                    <ButtonIGL action={() => {
                        props.redirect()
                    }} text={"Saiba mais"}/>
                    {props.logo && window.innerWidth > 760 && <img src={props.logo} alt=""/>}
                </div>

            </div>


        </div>
    )
}

export default Unidade
























