import React from 'react';
import './App.scss';
import {createTheme, ThemeProvider} from '@material-ui/core/styles'
import Routes from "./routes";
import {Whats} from "./assets";
import 'swiper/swiper.scss';
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/effect-fade/effect-fade.min.css"


const theme = createTheme({
    palette: {
        primary: {
            main: '#F18E00',
        },
        secondary: {
            main: '#0095F2'
        }
    },
})

function App() {
    //Todo:setup analytics
    return (
        <>
            <ThemeProvider theme={theme}>
                <Routes/>
            </ThemeProvider>
            <a href="https://wa.me/+553135682691?text=Ol%C3%A1%21+Gostaria+de+saber+mais+informa%C3%A7%C3%B5es+sobre+a+matr%C3%ADcula+do+Gabriela+Leopoldina.+" target="_blank" rel="noreferrer">
                <img className={"whats-icon"} src={Whats} alt=""/>
            </a>
        </>
    );
}

export default App;
