import React, {useEffect, useState} from "react"
import "./style.scss"
import {img_mission_1, img_mission_2, img_mission_3, img_mission_4} from "../../../../assets/home";
import {useIsMobile} from "../../../../utils";


function Mission() {
    const isMobile = useIsMobile()

    useEffect(() => {
        let element = document.getElementsByClassName("carousel-swiper")[0]
        new ResizeObserver(changeAbsoluteCard).observe(element)
    }, [])


    const content = [
        ["Possibilitar que as crianças e os jovens relacionem-se \nconsigo e com os outros, de maneira ética e compassiva.", img_mission_1],
        ["Apoiar nossos colaboradores em sua constante formação.", img_mission_2],
        ["Sem limitações, para ser referência na educação de crianças e jovens, no segmento da educação básica.", img_mission_3],
        ["Trabalho de maneira criativa e responsável, atentos às mudanças do mundo.", img_mission_4],
    ]

    const card_style_position = isMobile ?
        [
            {right: "18%", top: "18%"},
            {right: "3%", top: "10%"},
            {right: "18%", top: "1%"},
            {right: "11%", top: "-10%"},
        ]
        : [
            {right: "40%", top: "3.7vw"},
            {left: "40%", bottom: "0.6vw"},
            {right: "40%", bottom: "-1.6vw"},
            {left: "34%", bottom: "-4vw"},
        ];

    const img_style_place = window.innerWidth < 760 ? [
        {placeSelf: "flex-start"},
        {placeSelf: "flex-end"},
        {placeSelf: "flex-start"},
        {placeSelf: "flex-end"},
    ] : [{}];

    const [card_text_style_position, setTextStyle] = useState<{ left?: string, bottom?: string, right?: string, height?: string, width?: string }[]>(
        window.innerWidth > 760 ? [
            {left: "23%", bottom: "-16%"},
            {right: "20%", bottom: "-15%", height: "auto", width: "fit-content"},
            {left: "22%", bottom: "-12%"},
            {right: "22.5%", bottom: "-1.2vw", width: "88%"},
        ] : [
            // {right: "-5%", bottom: "25%"},
            {right: "-8%", bottom: "23%"},
            {right: "5%", bottom: "25%"},
            {right: "-5%", bottom: "25%"},
            {right: "5%", bottom: "25%"},
        ]
    )

    const changeAbsoluteCard = () => {
        let element_master = document.getElementsByClassName("carousel-swiper")[0] ?? undefined

        let width = element_master?.clientWidth ?? undefined

        let element = document.getElementsByClassName("mission-img")[0] ?? undefined

        let temp = [...card_text_style_position]

        if (element && isMobile && width) {
            temp[0] = {...temp[0], 'bottom': (-element.clientHeight * 80 / 434.42).toString() + "px"}
            setTextStyle(temp)
        }
    }


    return (
        <div className={"mission-container"} id={"mission-section"}>
            <h2>Missões e Valores</h2>

            <div className={"imgs-container"}>
                {content.map((e, index) => {
                        return <>
                            <div className="mission-card" style={card_style_position[index]}>
                                <img className={"mission-img"} src={e[1]} style={img_style_place[index]} alt=""/>
                                <div className={"card-text-img"} style={card_text_style_position[index]}>
                                    <p className={"text-mission"}>{e[0]}</p>
                                </div>
                            </div>
                        </>;
                    }
                )}
            </div>


        </div>
    )
}

export default Mission
























