import './style.scss'
import {footer_inst, footer_logo, footer_mail, footer_map, footer_phone, footer_whats} from "../../assets/fotter";
import {useIsMobile} from "../../utils";


function FooterBar() {
    const isMobile = useIsMobile()

    const itens = [
        [
            [footer_phone, "(31) 3568-2691", "tel:+553135682691"],
            [footer_whats, "(31) 3568-2692", "https://wa.me/+553135682692"],
        ],
        [
            [footer_mail, "admissao@gabriela.com", "mailto:admissao@gabriela.com"],
            [footer_inst, "@institutogabrielaleopoldina", "https://www.instagram.com/institutogabrielaleopoldina/"],
        ],
        [
            [footer_map, "Rua Ordália, 339 - Glória, Belo Horizonte - MG, 30870-300", "https://www.google.com/maps/place/R.+Ord%C3%A1lia,+339+-+Gl%C3%B3ria,+Belo+Horizonte+-+MG,+30870-300/@-19.9042821,-44.0141982,19.25z/data=!4m5!3m4!1s0xa696a247ec2e2b:0x977b3aad4e04c4a0!8m2!3d-19.9043776!4d-44.0139263"],
            [footer_map, "Av. Miguel Perrela, 701 - Castelo, Belo Horizonte - MG, 31330-290", "https://www.google.com/maps/place/Institute+Gabriela+Leopoldina/@-19.876235,-43.9953735,21z/data=!4m13!1m7!3m6!1s0xa6913dce405b99:0x54970c174932b21a!2sAv.+Miguel+Perrela,+701+-+Castelo,+Belo+Horizonte+-+MG,+31330-290!3b1!8m2!3d-19.8762055!4d-43.9954158!3m4!1s0xa696a2467d599f:0x45ac594367f2dcbd!8m2!3d-19.8762429!4d-43.995359"],
        ]
    ]


    return (
        <div className={"footer-container"}>
            <div className="wave-bg">
                <div className="wave">
                    {!isMobile && <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path
                            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                            className="shape-fill"/>
                    </svg>}
                    {isMobile &&
                    <svg width="375" height="50" viewBox="0 0 375 50" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M140.84 17.7026C214.564 10.0555 292.741 -21.3636 357.274 24.0749C363.563 28.5027 369.457 33.4367 375 38.8158L375 53.5H6.10352e-05L9.15527e-05 16.472C45.1466 12.8461 97.1711 22.2323 140.84 17.7026Z"
                            fill="#0F3768"/>
                    </svg>
                    }
                </div>
            </div>

            <div className={"content"}>
                {!isMobile && <img className={'logo'} src={footer_logo} alt=""/>}

                {
                    itens.map((e) =>
                        <div className={"decription-div"}>
                            {
                                e.map((i) =>
                                    <div className={"item-div"}>
                                        <img src={i[0]} alt=""/>
                                        <a href={i[2]}><p>{i[1]}</p></a>
                                    </div>
                                )
                            }

                        </div>
                    )
                }
            </div>

            {isMobile && <img className={'logo'} src={footer_logo} alt=""/>}
        </div>

    )
}

export default FooterBar;