import {BrowserRouter, Route, Switch} from 'react-router-dom'

import Home from './page/home/index'
import React from "react";
import School from "./page/school";
import FooterBar from "./components/footerbar";
import Build from "./page/build";
import Gallery from "./page/gallery";
import HeaderProvider from "./components/headerbar/provider/header";
import DrawerProvider from "./components/drawer/provider/drawer";


function Routes() {


    return (
        <BrowserRouter>
            <DrawerProvider>
                <HeaderProvider>
                    <Switch>
                        <Route path="/" component={Home} exact/>
                        <Route path="/school" component={School} exact/>
                        <Route path="/build" component={Build} exact/>
                        <Route path="/galeria" component={Gallery} exact/>
                    </Switch>
                    {window.location.pathname !== "/build" && <FooterBar/>}
                </HeaderProvider>
            </DrawerProvider>
        </BrowserRouter>
    )
}

export default Routes