import './style.scss'
import React, {MouseEventHandler} from "react";

interface ButtonIGLProp {
    text?: string;
    action: MouseEventHandler;
    children?: React.ReactNode;
}

function ButtonIGL(props: ButtonIGLProp) {


    return (
        <button className={"button-igl"} onClick={props.action}>
            {props.children && props.children}
            <p className={'btn-igl-txt'}>{props.text}</p>
        </button>
    );
}

export default ButtonIGL;