import React from "react"
import "./style.scss"
import {confetti} from "../../assets/build";
import {logo_white} from "../../assets";
import {Fab} from "@material-ui/core";
import {Link} from "react-router-dom";


function Build() {
    return (
        <main className="build-page">
            <div className={'top'}>
                <img className={'confetti'} src={confetti} alt=""/>
                <img className={'logo'} src={logo_white} alt=""/>
                <img className={'confetti'} src={confetti} alt="" style={{transform: "rotateY(180deg)"}}/>
            </div>
            <p>Estamos preparando um novo espaço para vocês. <br/>
                Logo estaremos de volta com muitas novidades para vocês.</p>
            <Link to="/#admissao">
                <Fab variant="extended" aria-label="Contatar" color="primary">
                    Estude conosco
                </Fab>
            </Link>
        </main>
    )
}

export default Build;