import React, {useContext, useEffect, useState} from "react"
import "./style.scss"
import {Download} from "../../assets/gallery";
import {useIsMobile} from "../../utils";
import {logo_white} from "../../assets";
import {DrawerContext} from "../../components/drawer/provider/drawer";

const photos = require('./photos.json');

function Gallery() {
    const {setShowDrawer} = useContext(DrawerContext);
    const isMobile = useIsMobile()

    const [section, setSection] = useState(Object.keys(photos)[0]);
    const [group, setGroup] = useState(Object.keys(photos[section])[0]);


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(0)

    const updateGroup = (name: string, _section: string = section) => {
        let total = photos[_section][name].length
        let limit = Math.floor(total / 12)
        limit = total % 12 > 0 ? limit : limit - 1
        setGroup(name);
        setPage(0)
        setLimit(limit)
    }

    useEffect(() => {
        updateGroup(Object.keys(photos[section])[0])
    }, [])

    const openDrawer = () => setShowDrawer(true)


    const getButtonsSection = () => {
        return <>
            {Object.keys(photos).map(sections => {
                return <button className={(section === sections ? 'active' : '')}
                               onClick={async () => {
                                   setSection(sections);
                                   updateGroup(Object.keys(photos[sections])[0], sections);
                               }}>
                    {sections}</button>;
            })}
        </>;
    }

    return (
        <main className={"gallery-container"}>
            <div className={"section-select"}>

                <div className={"header not-show-desktop"}>
                    <img src={logo_white} alt=""/>
                    <i className="fas fa-bars" onClick={openDrawer}/>
                </div>

                {
                    isMobile ?
                        <div className={"horizontal-scroll"}>
                            {getButtonsSection()}
                        </div>
                        : getButtonsSection()

                }

            </div>

            <div className={"group-container"}>
                <div className={"group-select"}>
                    {Object.keys(photos[section]).map(groups => {
                        return <button className={(group === groups ? 'active' : '')}
                                       onClick={() => updateGroup(groups)}>
                            <span>{groups}</span></button>;
                    })}
                </div>

                <div className={"photos-container"}>
                    <div className={"icon-container not-show-moblie " + (page === 0 ? "not-show" : "")}
                         onClick={() => setPage(page - 1)}><i className="fas fa-chevron-left"/></div>

                    <div className={"photo-grid"}>
                        {
                            photos[section][group]?.slice(page * 12, (page + 1) * 12)?.map((photo: string) => {
                                let name = decodeURIComponent(photo.split('?')[0]).split('/').slice(-1)[0];
                                return <div className={"img-container"}>
                                    <img src={photo} alt={name}/>
                                    <a className={"img-downloader"} download={name} href={photo} title={name}>
                                        Faça o Download
                                        <img src={Download} alt={name}/>
                                    </a>
                                </div>;
                            }) ?? <></>
                        }
                    </div>

                    <div className={"icon-container not-show-moblie " + (page === limit ? "not-show" : "")}
                         onClick={() => setPage(page + 1)}><i className="fas fa-chevron-right"/></div>
                </div>
            </div>

        </main>
    )
}

export default Gallery