import React, {useContext} from "react"
import "./style.scss"
import {
    slide1,
    slide1_mobile,
    slide2,
    slide2_mobile,
    slide3,
    slide3_mobile, slide4,
    slide4_mobile
} from '../../../../assets/home'

import {Swiper, SwiperSlide} from 'swiper/react';

import SwiperCore, {Autoplay, EffectFade, Pagination} from 'swiper/core';
import {Fab} from '@material-ui/core';
import {logo_white} from "../../../../assets";
import {Link} from "react-router-dom";
import {DrawerContext} from "../../../../components/drawer/provider/drawer";


SwiperCore.use([Autoplay, Pagination, EffectFade]);


function Carousel() {
    const {setShowDrawer} = useContext(DrawerContext);

    let images = window.innerWidth > 760
        ? [
            slide1,
            slide2,
            slide3,
            slide4
        ] : [
            slide1_mobile,
            slide2_mobile,
            slide3_mobile,
            slide4_mobile
        ];

    let text = [
        "Nós acreditamos em todos os alunos.",
        `O erro como oportunidade de transformação.\nCoragem: agradecer os desafios.`,
        "Fazer a coisa certa mesmo quando ninguém está vendo.",
        "Questionar, examinar e desafiar com coração e mente abertos."
    ];

    const openDrawer = () => setShowDrawer(true)


    const handleEstude = () => {
        document!.getElementById("forms-section")!.scrollIntoView({
            block: "start",
            behavior: "smooth",
        })
    }

    return (
        <div className={'carousel-swiper'}>


            <Swiper
                effect={'fade'}
                centeredSlides={true}
                autoplay={{
                    "delay": 6000,
                    "disableOnInteraction": false
                }}

            >
                <div className={"redirect-content"}>
                    <img className={"logo-white"} src={logo_white} alt=""/>
                    <i className="fas fa-bars not-show-desktop" onClick={openDrawer}/>

                    <Link to="/" className="not-show-moblie"><p>Home</p></Link>
                    <Link className="not-show-moblie" to="/school"><p>A escola</p></Link>
                    {/*<Link to="/familia" className="not-show-moblie"><p>Família IGL</p></Link>*/}
                    <Link to="/build" className="not-show-moblie"><p>Família IGL</p></Link>
                    {/*<Link to="/unidades" className="not-show-moblie"><p>Unidade</p></Link>*/}
                    <Link to="/build" className="not-show-moblie"><p>Unidade</p></Link>
                    <Link to="/galeria" className="not-show-moblie"><p>Galeria</p></Link>
                </div>
                {
                    images.map(
                        (value, index) =>
                            <SwiperSlide>
                                <img src={value} alt=""/>
                                <div className={"banner-back-color"}/>
                                <p className={"texts"}> {text[index]}</p>
                                <Fab variant="extended" aria-label="Contatar" color="secondary" onClick={handleEstude}>
                                    Estude conosco
                                </Fab>
                            </SwiperSlide>
                    )
                }

            </Swiper>

        </div>
    )
}


export default Carousel