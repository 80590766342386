import React, {useCallback, useEffect, useRef, useState} from "react"
import "./style.scss"
import ButtonIGL from "../../../../components/button";


//https://stackoverflow.com/questions/62497110/detect-scroll-direction-in-react-js
const isElementInViewport = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function Video() {
    const textRef = useRef<HTMLHeadingElement>(null);
    const [y, setY] = useState(window.scrollY);


    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (y < window.scrollY) {
                if (textRef.current != null && isElementInViewport(textRef.current!)) {
                    textRef.current!.className = "text-focus-in subtitle";
                    window.removeEventListener("scroll", handleNavigation);
                }
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);


        return () => window.removeEventListener("scroll", handleNavigation);
    }, [handleNavigation]);


    return (
        <div className={"video-container"} id={"video-section"}>

            <h2>A História</h2>


            <iframe src="https://www.youtube.com/embed/6absuxaFddE" title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>

            <div className={"row"}>
                <p ref={textRef} className={"subtitle"}>Uma escola baseada no legado de <br/> uma família para a construção do <br/> <span>legado de uma geração</span>.</p>
                <ButtonIGL text={"Saiba mais"} action={() => {
                    // history.push('/build')
                    window.location.href = '/build'
                }}/>

            </div>

        </div>
    )
}

export default Video
























