import {createContext, useState} from "react";
import TempDrawer from "../index";

export const DrawerContext = createContext({} as any)

const DrawerProvider = ({children}: any) => {
    const [showDrawer, setShowDrawer] = useState(false)

    return (
        <DrawerContext.Provider value={{showDrawer, setShowDrawer}}>
            <TempDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer}/>

            {children}
        </DrawerContext.Provider>
    )
}

export default DrawerProvider