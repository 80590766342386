import React from "react"
import "./style.scss"
import {img_casas_1, img_casas_2, img_casas_3, img_casas_4, img_casas_5} from "../../../../../assets/home";
import ButtonIGL from "../../../../../components/button";
import {Swiper, SwiperSlide} from 'swiper/react';
import {useIsMobile} from "../../../../../utils";


function Casas() {
    const isMobile = useIsMobile()


    const logo_casas = [
        img_casas_1,
        img_casas_2,
        img_casas_3,
        img_casas_4,
        img_casas_5];

    return (
        <div className={"casas-container"} id={"casas-section"}>
            <h2>Projeto de Casas{window.innerWidth < 760 ? <br/> : null}<span>Unidade Glória</span></h2>

            {
                !isMobile
                    ? <div className={"div-imgs-casas"}>
                        {
                            logo_casas.map((e) => <img src={e} alt=""/>)
                        }
                    </div> :
                    <Swiper
                        effect={'fade'}
                        centeredSlides={true}
                        pagination={true}
                        autoplay={{
                            "delay": 2500,
                            "disableOnInteraction": false
                        }}

                    >
                        {
                            logo_casas.map(
                                (value) =>
                                    <SwiperSlide>
                                        <img src={value} alt=""/>
                                    </SwiperSlide>
                            )
                        }

                    </Swiper>
            }


            <div className={"details"}>
                <p>Um jeito inovador de incentivar os adolescentes a buscarem conhecimento, autonomia, colaboração<br/> em grupo e protagonismo.</p>
                <ButtonIGL action={() => {
                    window.location.href = '/build'
                }} text={"Entenda melhor"}/>
            </div>


        </div>
    )
}

export default Casas
























