import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import './style.scss'
import {logo_new} from "../../assets";
import {Link} from "react-router-dom";



export default function TempDrawer({showDrawer, setShowDrawer}: any) {

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setShowDrawer(open);
        };

    const list = () => (
        <Box
            sx={{width: 250}}
            role="presentation"
            className={'drawer'}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <div className={'header'}>
                <img src={logo_new} alt=""/>
                <i className="fas fa-times"/>
            </div>
            <List>
                {[{text: "Home", action: '/'},
                    {text: "A Escola", action: '/build'},
                    {text: "Família IGL", action: '/build'},
                    {text: "Unidade", action: '/build'},
                    {text: "Galeria", action: '/galeria'}].map((item, _) => (
                    <ListItem button key={item.text}>
                       <Link to={item.action}>
                        <ListItemText primary={item.text}/>
                       </Link>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Drawer
            anchor={"left"}
            open={showDrawer}
            className={"not-show-desktop"}
            onClose={toggleDrawer(false)}
        >
            {list()}
        </Drawer>
    );
}
